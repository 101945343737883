<template>
  <div class="invitation-registration-view">
    <v-container fill-height>
      <v-layout justify-center fill-height class="invitation-registration-view__layout">
        <div class="invitation-registration-view__form-wrapper">
          <div class="invitation-registration-view__form-header">
            <div class="invitation-registration-view__form-header-logo-wrapper">
              <Logo large />
            </div>
            <div class="invitation-registration-view__form-header-title-wrapper">
              <FormTitle transform="capitalize">Set your password</FormTitle>
            </div>
          </div>
          <div class="invitation-registration-view__form-body">
            <div class="invitation-registration-view__form-text-wrapper mb-5">
              <p class="invitation-registration-view__form-text mb-0">
                In order to finish your registration you have to set yourself a secure password
                which you'll use to log into your dashboard.
              </p>
            </div>
            <ResetPasswordForm
              @submit="submitPasswordForm"
              :password.sync="passwordForm.password"
              :passwordConfirmation.sync="passwordForm.confirmPassword"
            />
          </div>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { updatePasswordByToken } from '@/mixins';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm';
import FormTitle from '@/components/typography/FormTitle';
import Logo from '@/components/Logo';

export default {
  name: 'ResetPassword',
  mixins: [updatePasswordByToken],
  components: {
    ResetPasswordForm,
    FormTitle,
    Logo,
  },
};
</script>

<style lang="scss">
.invitation-registration-view {
  height: 100%;
}
.invitation-registration-view__layout {
  padding-top: 120px;
}
.invitation-registration-view__form-wrapper {
  width: 360px;
}
.invitation-registration-view__form-header-logo {
  height: 67px;
}
.invitation-registration-view__form-header-logo-wrapper {
  margin-bottom: 33px;
}
.invitation-registration-view__form-text {
  margin-bottom: 0px;
  color: $text-semi-dark;
  font-size: 1rem;
  font-weight: 300;
  a {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
  }
}
.invitation-registration-view__form-body {
  margin-top: 21px;
}
</style>
